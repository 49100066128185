@font-face {
    font-family: 'Red Hat Display';
    src: url('./fonts/RedHatDisplay-BoldItalic.woff2') format('woff2'),
        url('./fonts/RedHatDisplay-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: block;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('./fonts/RedHatDisplay-ExtraBold.woff2') format('woff2'),
        url('./fonts/RedHatDisplay-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('./fonts/RedHatDisplay-Bold.woff2') format('woff2'),
        url('./fonts/RedHatDisplay-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('./fonts/RedHatDisplay-Black.woff2') format('woff2'),
        url('./fonts/RedHatDisplay-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('./fonts/RedHatDisplay-BlackItalic.woff2') format('woff2'),
        url('./fonts/RedHatDisplay-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: block;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('./fonts/RedHatDisplay-SemiBold.woff2') format('woff2'),
        url('./fonts/RedHatDisplay-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('./fonts/RedHatDisplay-LightItalic.woff2') format('woff2'),
        url('./fonts/RedHatDisplay-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: block;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('./fonts/RedHatDisplay-Medium.woff2') format('woff2'),
        url('./fonts/RedHatDisplay-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('./fonts/RedHatDisplay-Light.woff2') format('woff2'),
        url('./fonts/RedHatDisplay-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('./fonts/RedHatDisplay-ExtraBoldItalic.woff2') format('woff2'),
        url('./fonts/RedHatDisplay-ExtraBoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: block;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('./fonts/RedHatDisplay-Regular.woff2') format('woff2'),
        url('./fonts/RedHatDisplay-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('./fonts/RedHatDisplay-Italic.woff2') format('woff2'),
        url('./fonts/RedHatDisplay-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: block;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('./fonts/RedHatDisplay-SemiBoldItalic.woff2') format('woff2'),
        url('./fonts/RedHatDisplay-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: block;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('./fonts/RedHatDisplay-MediumItalic.woff2') format('woff2'),
        url('./fonts/RedHatDisplay-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: block;
}

